<template>
  <div>
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center subtitle-4 black--text mb-10">
          الزبائن
        </h1>

        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="table.headers"
              loading-text="جاري التحميل ... الرجاء الانتظار"
              :items="table.teacherData"
              :options.sync="tableOptions"
              :server-items-length="table.totalTeacherData"
              :loading="table.loading"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [10, 50, 100],
              }"
            >
              <template slot="item.notification_id" slot-scope="props">
                {{ (tableOptions.page - 1) * tableOptions.itemsPerPage + props.index + 1 }}
              </template>
              <template v-slot:item.notification_type="{ item }">
                <span v-if="item.notification_type === 'general'">عام</span>
                <span v-else>خاص</span>
              </template>
              <template v-slot:item.customer_img="{ item }">
                <img
                  v-if="item.customer_img"
                  class="teacher_image_table"
                  :src="$store.state.FileUrl + item.customer_img"
                  alt
                  width="50"
                  height="50"
                  @click="showImage(item.customer_img)"
                />
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="EditPassword(item)">
                      fa-key
                    </v-icon>
                  </template>
                  <span>تعديل الرمز</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <!-- image Teacher dialog -->
    <v-dialog v-model="table.showImageDailog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">عرض الصورة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-row class="justify-center">
                <img :src="this.$store.state.FileUrl + table.imageUrlForShow" alt width="300" height="200" />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="table.showImageDailog = false">
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->
    <!-- edit customer password dialog -->
    <v-dialog v-model="editPasswordDailog.open" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل رمز الزبون</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="editPasswordDailog.isFormValid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.customer_password_show"
                    label="الرمز"
                    :rules="Rules.passwordRules"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.ensurePassword"
                    label="تاكيد الرمز"
                    :rules="Rules.ensurePasswordRules"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editPasswordDailog.open = false">
            الغاء
          </v-btn>
          <v-btn
            color="primary white--text"
            :loading="editPasswordDailog.loading"
            :disabled="!editPasswordDailog.isFormValid"
            @click="submitEditPassword"
          >
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit customer password dialog -->
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center">
          {{ dialogData.bodyText }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false">
            تم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import sha512 from 'js-sha512'

export default {
  data () {
    return {
      editPasswordDailog: {
        loading: false,
        isFormValid: false,
        open: false,
      },

      addDailog: {
        loading: false,
        isFormValid: false,
        open: false,
      },

      isCustomerSelectShowing: false,

      notification_typeItems: [
        { text: 'عام', value: 'general' },
        { text: 'خاص', value: 'specific' },
      ],

      deleteItemLoading: false,

      editedItem: {},

      Rules: {
        title: [value => !!value || 'العنوان مطلوب'],
        body: [value => !!value || 'المحتوى مطلوب'],
        type: [value => !!value || 'النوع مطلوب'],
      },

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      customersItems: [],

      addData: {
        notification_title: null,
        notification_body: null,
        notification_type: null,
        notification_for_customer: null,
      },

      deletedItem: {},

      tableOptions: {},

      table: {
        search: null,
        totalTeacherData: 0,
        teacherData: [],
        loading: true,
        showImageDailog: false,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'notification_id',
          },
          {
            text: 'الاسم',
            sortable: false,
            value: 'customer_name',
          },
          {
            text: 'اسم المستخدم',
            sortable: false,
            value: 'customer_username',
          },
          {
            text: 'الهاتف',
            sortable: false,
            value: 'customer_mobile',
          },

          // {
          //   text: 'الرمز',
          //   sortable: false,
          //   value: 'customer_password_show',
          // },
          {
            text: 'المواليد',
            sortable: false,
            value: 'customer_birthday',
          },
          {
            text: 'العمل',
            sortable: false,
            value: 'customer_jop',
          },
          {
            text: 'الصورة',
            sortable: false,
            value: 'customer_img',
          },
          {
            text: 'التاريخ',
            sortable: false,
            value: 'customer_created_at',
          },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },

      customerSelectLoading: false,

      Rules: {
        passwordRules: [value => !!value || 'الرمز مطلوب'],

        ensurePasswordRules: [value => !!value || 'تاكيد الرمز مطلوب'],
      },
    }
  },
  watch: {
    tableOptions: {
      handler () {
        this.getDataAxios()
      },
      deep: true,
    },
  },

  // created() {
  //   this.getCustomerData()
  // },
  methods: {
    getDataAxios () {
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .get(`/customer/${page}/${itemsPerPage}`)
        .then(Response => {
          if (Response.data.status === 401) {
            this.$store.dispatch('submitLogout')
          } else {
            this.table.loading = false
            this.table.teacherData = Response.data.results
            this.table.totalTeacherData = Response.data.count
          }
        })
        .catch(error => {
          this.table.loading = false
          console.log('error', error)
        })
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    showImage (image) {
      this.table.showImageDailog = true
      this.table.imageUrlForShow = image
    },

    EditPassword (item) {
      this.editedItem = { ...item }
      this.editPasswordDailog.open = true
    },

    submitEditPassword () {
      if (this.editedItem.customer_password_show !== this.editedItem.ensurePassword) {
        this.showDialogfunction('تاكيد الرمز خاطئ', '#FF5252')
      } else {
        this.EditPasswordLoading = true
        this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        this.axios
          .put('customer', {
            customer_id: this.editedItem.customer_id,
            customer_password_show: this.editedItem.customer_password_show,
            customer_password: sha512(this.editedItem.customer_password_show),
          })
          .then(Response => {
            this.editPasswordDailog.loading = false
            if (Response.data.error == false) {
              this.editPasswordDailog.open = false
              this.getDataAxios()
              this.showDialogfunction(Response.data.results, 'primary')
            } else {
              this.showDialogfunction(Response.data.results, '#FF5252')
            }
          })
          .catch(error => {
            this.editPasswordDailog.loading = false
            this.editPasswordDailog.open = false
            this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
            console.log('error', error)
          })
      }
    },
  },
}
</script>

<style scoped>
.teacher_image_table {
  cursor: pointer;
}
a {
  text-decoration: none;
}
.search-btn {
  width: 49px;
  height: 40px;
  padding: 10px;
  background: #2196f3;
  font-size: 17px;
  border: 1px solid grey;
  border-right: none;
  cursor: pointer;
  line-height: 100%;
  border-radius: 7px;
}
table.v-table tbody td {
  color: black;
}
</style>
